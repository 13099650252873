import TeacherManagement from "./User/TeacherManagement";
import StudentManagement from './User/StudentManagement';
import UserManagement from './User';

import ClassManagement from "./Item/ClassManagement";

export const UserManage = {
  UserManagement,
  TeacherManagement,
  StudentManagement,
};

export const ItemManage = {
  ClassManagement,
};