import React from 'react';
import { TeacherUpload } from "../../components/file-upload/file-upload";


const TeacherUploadsListeningPartB2 = () => {
    return (<div>
      <h2>Listening Part B2 Teacher Uploads</h2>
      <TeacherUpload has_data_files="true" mock_paper_type="listening_part_b2"/>
      </div>
      )
}

export default TeacherUploadsListeningPartB2;