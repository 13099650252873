import React from 'react';
import { StudentSubmissionMain } from "../../components/file-upload/file-upload";


const StudentSubmissionsWritingPartA = () => {
    return (<>
      <StudentSubmissionMain mock_paper_type="writing_part_a"/>
      </>
      )
}

export default StudentSubmissionsWritingPartA;