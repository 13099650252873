// import type { Action } from '@reduxjs/toolkit';
import { configureStore } from "@reduxjs/toolkit";
// import type { TypedUseSelectorHook } from 'react-redux';
// import {
//   useDispatch as useReduxDispatch,
//   useSelector as useReduxSelector,
// } from "react-redux";
// import type { ThunkAction } from 'redux-thunk';
import { rootReducer } from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
});

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

// export const useSelector = useReduxSelector;
// export const useDispatch = () => useReduxDispatch();
export * from "./rootReducer";
