import React from 'react';
import { TeacherUpload } from "../../components/file-upload/file-upload";


const TeacherUploadsListeningPartB1 = () => {
    return (<div>
      <h2>Listening Part B1 Teacher Uploads</h2>
      <TeacherUpload has_data_files="true" mock_paper_type="listening_part_b1"/>
      </div>
      )
}

export default TeacherUploadsListeningPartB1;