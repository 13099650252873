export const studentsByAnswersAndTimeData = [
  {
    name: "Aileen Adele",
    questions: 25,
    minutes: 11,
    lastPracticed: 10,
    data: [
      {
        game: "The Village",
        subGame: "Writing (Lv 1)",
        questions: "12",
        timeSpent: "3",
        score: "15",
      },
      {
        game: "Word Dash",
        subGame: "",
        questions: "13",
        timeSpent: "2",
        score: "",
      },
    ],
  },
  {
    name: "Bartholemew Bin",
    questions: 50,
    minutes: 46,
    lastPracticed: 10,
    data: [
      {
        game: "The Village",
        subGame: "Reading (Lv 2)",
        questions: "25",
        timeSpent: "23",
        score: "77",
      },
      {
        game: "Word Dash",
        subGame: "",
        questions: "10",
        timeSpent: "2",
        score: "",
      },
      {
        game: "The Village",
        subGame: "Listening A (Lv 1)",
        questions: "15",
        timeSpent: "21",
        score: "46",
      },
    ],
  },
  {
    name: "Cassandra Chan",
    questions: 48,
    minutes: 35,
    lastPracticed: 10,
    data: [
      {
        game: "Word Dash",
        subGame: "",
        questions: "48",
        timeSpent: "35",
        score: "",
      },
    ],
  },
  {
    name: "Dayton Daring",
    questions: 0,
    minutes: 0,
    lastPracticed: 0,
    data: [],
  },
];

export const studentsBySkillData = [
  {
    name: "Aileen Adele",
    total: 124,
    practiced: 32,
    proficient: 12,
    mastered: 12,
  },
  {
    name: "Bartholemew Bin",
    total: 57,
    practiced: 2,
    proficient: 5,
    mastered: 11,
  },
  {
    name: "Cassandra Chan",
    total: 81,
    practiced: 24,
    proficient: 5,
    mastered: 8,
  },
  {
    name: "Dayton Daring",
    total: 62,
    practiced: 22,
    proficient: 7,
    mastered: 9,
  },
  {
    name: "Evangeline Ein",
    total: 88,
    practiced: 11,
    proficient: 1,
    mastered: 0,
  },
  {
    name: "Friday Fungi",
    total: 12,
    practiced: 1,
    proficient: 7,
    mastered: 9,
  },
  {
    name: "Gunther Graham",
    total: 44,
    practiced: 26,
    proficient: 17,
    mastered: 18,
  },
];

export const scoreData = [
  [25, 25, 25, 25],
  [10, 30, 30, 30],
  [20, 20, 30, 30],
  [40, 20, 20, 20],
  [15, 25, 30, 30],
  [35, 20, 25, 20],
  [10, 40, 30, 20],
  [25, 25, 20, 30],
  [20, 30, 25, 25],
  [10, 25, 35, 30],
  [30, 20, 25, 25],
  [25, 30, 20, 25],
  [30, 30, 20, 20],
  [30, 25, 25, 20],
  [15, 20, 35, 30],
  [20, 30, 30, 20],
  [25, 30, 30, 15],
  [30, 25, 20, 25],
  [20, 20, 30, 30],
  [20, 25, 25, 30],
];
export const classData = [
  {
    title: "Class 1",
    value: "class_123",
  },
  // {
  //   title: "Class 2",
  //   value: "class-2",
  // },
  // {
  //   title: "Class 3",
  //   value: "class-3",
  // },
];
export const studentsData = [
  {
    name: "John Kei",
    value: "std_399293",
  },
  {
    name: "Alin Kei",
    value: "std_10001",
  },
  // {
  //   name: "Cassandra Chan",
  //   value: "student-3",
  // },
  // {
  //   name: "Dayton Daring",
  //   value: "student-4",
  // },
  // {
  //   name: "Evangeline Ein",
  //   value: "student-5",
  // },
  // {
  //   name: "Friday Fungi",
  //   value: "student-6",
  // },
  // {
  //   name: "Gunther Graham",
  //   value: "student-7",
  // },
];
export const studentScoreData = [
  {
    category: "listening a",
    data: [
      {
        question: "Level 1",
        smartScore: "100",
        questionAnswered: "23",
        timeSpent: "12",
        lastPracticed: "January 14, 2024",
      },
      {
        question: "Level 2",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 3",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 4",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
    ],
  },
  {
    category: "listening b",
    data: [
      {
        question: "Level 1",
        smartScore: "100",
        questionAnswered: "55",
        timeSpent: "30",
        lastPracticed: "January 11, 2024",
      },
      {
        question: "Level 2",
        smartScore: "63",
        questionAnswered: "12",
        timeSpent: "99",
        lastPracticed: "January 11, 2024",
      },
      {
        question: "Level 3",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 3",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
    ],
  },
  {
    category: "reading",
    data: [
      {
        question: "Level 1",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 2",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 3",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 4",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
    ],
  },
  {
    category: "speaking",
    data: [
      {
        question: "Level 1",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 2",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 3",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 4",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
    ],
  },
  {
    category: "writing",
    data: [
      {
        question: "Level 1",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 2",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 3",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
      {
        question: "Level 4",
        smartScore: "",
        questionAnswered: "",
        timeSpent: "",
        lastPracticed: "",
      },
    ],
  },
];
