import React from 'react';
import { TeacherUpload } from "../../components/file-upload/file-upload";


const TeacherUploadsWritingPartB = () => {
    return (<div>
      <h2>Writing Part B Teacher Uploads</h2>
      <TeacherUpload mock_paper_type="writing_part_b"/>
      </div>
      )
}

export default TeacherUploadsWritingPartB;